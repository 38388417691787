<template>
  <div>
    <b-modal
      id="player-prompt"
      v-model="show"
      :title="$t('players.modal_title')"
      header-bg-variant="red"
      header-text-variant="white"
      body-bg-variant="white"
      body-text-variant="dark"
      footer-bg-variant="white"
      footer-text-variant="red"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            <h5 class="text-left">
              {{ $t("players.add") }}
            </h5>
            <hr />
            <p class="text-red">
              {{ error }}
            </p>
            <b-input-group class="mb-2">
              <b-form-input
                v-model="inputFriend"
                type="text"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-red"
                  @click="setPlayer()"
                >
                  {{
                    $t("players.save")
                  }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <template v-if="players.length > 0">
              <h5 class="text-left">
                {{ $t("players.players") }}
              </h5>
              <hr />
              <b-input-group
                v-for="(player, index) in players"
                :key="index"
                class="mb-2"
              >
                <b-form-input
                  v-model="players[index]"
                  type="text"
                  @keyup="modifyPlayer()"
                ></b-form-input>

                <b-input-group-append>
                  <b-button
                    variant="dark"
                    @click="removePlayer(index)"
                  >
                    <b-icon icon="x" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </template>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <p
            class="float-left"
            v-html="appText('add_players')"
          ></p>
        </div>
        <div class="w-100">
          <router-link
            v-if="$store.getters.loggedIn"
            tag="b-button"
            size="lg"
            class="hubu-btn"
            :to="{ name: 'ChooseCards' }"
          >
            {{ $t('manage_cards') }}
          </router-link>
          <b-button
            variant="outline-red"
            size="lg"
            class="float-right"
            @click="play"
          >
            {{ $t("play") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "Players",

  components: {},
  data () {
    return {
      wink: this.$store.getters.getWink,
      shuffled: true,
      error: "",
      inputFriend: "",
      players:
        typeof Cookies.get("players") !== "undefined"
          ? JSON.parse(Cookies.get("players"))
          : [],
      show: false,
      headerBgVariant: "red",
      headerTextVariant: "white",
      bodyBgVariant: "white",
      bodyTextVariant: "dark",
      footerBgVariant: "white",
      footerTextVariant: "red",
    };
  },

  computed: {
    isStillAllowedToPlay () {
      return this.$store.getters.isStillAllowedToPlay;
    },
    loggedIn () {
      return this.$store.getters.loggedIn;
    },
  },
  
  methods: {
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object" ? this.$store.getters.getAppTexts[text].content : text;
    },
    winkchange (e) {
      e.stopPropagation();
      e.preventDefault();
      this.wink = !this.wink;
      this.$store.dispatch("setWink", this.wink);
    },
    play () {
      this.show = false;
      if(!this.$store.getters.loggedIn) {
        this.$emit("play");
      }
      if(!this.isStillAllowedToPlay){ 
        this.$bvModal.show("confirm-prompt");
      } else {
        this.confirm();
      }
    },
    confirm () {
      this.$emit("play");
    },
    modifyPlayer () {
      if (this.players.length > 0) {
        Cookies.set("players", JSON.stringify(this.players));
      }
    },
    setPlayer () {
      if (this.inputFriend !== "") {
        this.players.push(this.inputFriend);
        Cookies.set("players", JSON.stringify(this.players));
        this.inputFriend = "";
        this.error = "";
      }
    },

    removePlayer (index) {
      if (this.players.length > 0) {
        if (typeof this.players[index] !== "undefined") {
          this.players.splice(index, 1);
          Cookies.set("players", JSON.stringify(this.players));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-btn {
  background-color: $white;
  color: $red;
  border-color: $red;
}

.hubu-btn:disabled {
  background-color: $black !important;
  color: $white;
  opacity: 1;
  border-color: $black;
}

.wink {
  background-color: $red;
  color: $white;
  border-color: $red;
}
.hubu-checkbox {
  &:checked ~ label {
    &:before {
      border: 1555px solid $red;
    }
    &:after {
      transform: rotate(-45deg) scale(1);
    }
  }
}
</style>