<template>
  <div>
    <b-modal
      v-if="show && $store.getters.loggedIn && organization"
      id="confirm-prompt"
      :title="$t('newgame.new')"
      header-bg-variant="red"
      header-text-variant="white"
      body-bg-variant="white"
      body-text-variant="dark"
      footer-bg-variant="white"
      footer-text-variant="red"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col
            v-if="canPlay"
            cols="12"
          >
            <h5 class="text-left">
              {{ $t("newgame.heading") }}
            </h5>
            <hr />
            {{ $t("newgame.info") }}
            <h5 class="mt-3">
              {{ $t("newgame.left") }}:&nbsp;
              <b> {{ organization.credits }} </b>
            </h5>
          </b-col>
          <b-col v-if="!canPlay">
            {{ appText('no_games_left') }}
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left"></p>
        </div>
        <div class="w-100">
          <b-button @click="$bvModal.hide('confirm-prompt')">
            {{ $t("newgame.no") }}
          </b-button>

          <b-button
            v-if="canPlay"
            variant="outline-red"
            size="lg"
            class="float-right"
            @click="play"
          >
            {{ $t("submit") }}
          </b-button>

          <a
            v-if="!canPlay"
            :href="appText('Buy_url')"
          >
            <b-button
              variant="outline-red"
              size="lg"
              class="float-right"
              @click="play"
            >
              {{ $t("auth.register") }}
            </b-button>
          </a>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "NewGame",
  data () {
    return {
      show: true,
    };
  },

  computed: {
    canPlay () {
      return this.$store.getters.loggedIn ? this.organization?.credits > 0 : false;
    },
    games () {
      return this.$store.getters.getMyGames;
    },
    user () {
      return this.$store.getters.getUserData;
    },
    organization () {
      return this.$store.getters.getOrganization;
    },
  },

  async mounted () {
    await  this.$store.dispatch("getMyGames").then(() => {
      this.$forceUpdate();
    });
  },

  methods: {
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object" ? this.$store.getters.getAppTexts[text].content : text;
    },
    play () {
      this.$emit("play");
    },
  },
};
</script>