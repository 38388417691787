<template>
  <div>
    <b-form-group class="mt-3 font-weight-bold">
      <label :for="'select-' + masterDeckId">{{ computedDeckTitle }}</label>
      <b-form-select
        :id="'select-' + masterDeckId"
        v-model="selected"
        :options="options"
        @change="handleChange"
      >
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import * as Text from "../Helpers/Text";
import { mapGetters } from "vuex";

export default {

  props: {
    deckTitle: {
      required: true,
    },

    templates: {
      required: true,
      type: Array,
    },

    masterDeckId: {
      required: true,
      type: Number,
    },
  },
  data () {
    return {
      selected: "standard",
      options: [
        // {
        //   value: "standard",
        //   text: this.$t("standard"),
        // },
        // {
        //   value: "wink",
        //   text: this.$t("wink"),
        // },
        // {
        //   value: "feedback",
        //   text: this.$t("feedback"),
        // },
      ],
    };
  },

  computed: {
    ...mapGetters(["standardTemplates"]),
    computedDeckTitle () {
      return Text.removeBRTag(this.deckTitle);
    },
  },

  async mounted () {
    var standardTemplates = this.standardTemplates?.deck.filter(deck => deck.title == "Standard");
    
    var value;
    standardTemplates.forEach((val) => {
      value = val.title;
      if(val.title == "Clin d'œil") {
        value = "wink";
      }
      value = Text.lcFirst(value);
      this.options.push({ value: value, text: val.title });
    });

    this.templates.forEach((val) => {
      this.options.push({ value: val.id, text: val.name });
    });
  },

  methods: {
    handleChange () {
      this.$emit("value-changed", this.selected);
    },
  },
};
</script>

<style scoped></style>
