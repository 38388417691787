<template>
  <div id="swpContainer">
    <b-row>
      <b-col
        class="text-center"
        :class="{
          'mt-1': !isMobile,
          'mb-5': !isMobile,
          'my-3 mx-1': isMobile,
        }"
      >
        <h5 v-if="appText('title_main')">
          {{ appText("title_main") }}
        </h5>
      </b-col>
    </b-row>
    <div
      v-if="isMobile && arrowVisible"
      class="info-arrow blink_me"
    >
      <font-awesome-icon
        v-if="isMobile && arrowVisible && loaded"
        class
        :icon="{ prefix: 'fas', iconName: 'arrow-right' }"
      />
    </div>
    <div class="deck-wrapper">
      <div class="deck-container">
        <template v-for="(link, index) in decks[$i18n.locale]">
          <SelectableDeck
            :key="index"
            :link="link"
            :select-deck="() => handleSelectDeck(link, index)"
            class="cursor-pointer"
            :class="{
              selected: isSelected(link.id),
            }"
          />
        </template>
      </div>
    </div>
    <div class="text-center mt-5 mb-3">
      <b-button
        class="hubu-btn"
        size="lg"
        :disabled="playBtnDisabled"
        variant="dark"
        @click="preselectCards"
      >
        {{ $t("play") }}
      </b-button>
    </div>

    <new-game
      v-if="textsLoaded"
      @play="play"
      @confirm="handleConfirmation"
    ></new-game>
    <preselect-cards
      v-if="selectedDecks.length > 0"
      :selected-decks="selectedDecks"
      :on-template-change="setTemplates"
      @deckChosen="handleDeckChosen"
    ></preselect-cards>
    <Players
      @play="play"
      @shufflechange="handleShuffleChange"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import SelectableDeck from "./SelectableDeck";
import Players from "./Game/Players";
import NewGame from "./Game/NewGame";
import PreselectCards from "./Game/PreselectCards";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    NewGame,
    SelectableDeck,
    Players,
    PreselectCards,
  },

  data () {
    return {
      arrowVisible: true,
      loaded: false,
      shuffled: true,
      lastDeckClicked: 1,
      customDeck: null,
      organizations: null,
      swiperOptions: {
        slidesPerView: Cookies.get("mobile") === "false" ? 5 : 1,
        spaceBetween: Cookies.get("mobile") === "false" ? 50 : 25,
        freeMode: true,
        loop: false,
      },
      swiperOptionsStandard: {
        slidesPerView: 2,
        spaceBetween: Cookies.get("mobile") === "false" ? 50 : 25,
        freeMode: false,
        loop: false,
      },
      selectedDecksAndTemplates: null,
      cards: [],
    };
  },

  computed: {
    ...mapGetters(["selectedDecks", "getBaseTemplateCards"]),
    playBtnDisabled () {
      return this.selectedDecks.length < 1;
    },
    iPad () {
      return window.screen.width / window.screen.height > 0.65 &&
        window.screen.width / window.screen.height < 0.9
        ? true
        : false;
    },
    textsLoaded () {
      return this.$store.getters.getTextsLoaded;
    },
    isMobile () {
      return Cookies.get("mobile") === "false" ? false : true;
    },
    decks () {
      return this.$store.getters.getDecks;
    },
    getInstructions () {
      return this.$store.getters.getInstr;
    },
    user () {
      return this.$store.getters.getUserData;
    },
  },

  async mounted () {
    this.$store.dispatch("setSelectedDecksAndTemplates", {});
    
    if (this.$store.getters.loggedIn) {
      await Promise.all([this.$store.dispatch("getMe"), this.$store.dispatch("getStandardTemplates")]);
    }

    this.$store.dispatch("setGameDecks", { shuffled: true, decks: [] });
  
    await this.$store
      .dispatch("getOrganizations")
      .then(() => {
        this.organizations = this.$store.getters.getOrganizations;
      })
      .catch((err) => {
        console.log(err);
      });

    this.loaded = true;
    setTimeout(() => {
      this.arrowVisible = false;
    }, 3000);
    this.$store.dispatch("getHeight");
  },

  async beforeMount () {
    this.$store.getters.loggedIn && await this.$store.dispatch("getMe");
    this.$store.dispatch("getDecks");
    this.$store.dispatch("getInstructions");
    this.setTemplates({ 1 : "standard" });
  },

  methods: {
    setTemplates (newTemplates) {
      this.$store.dispatch("setSelectedDecksAndTemplates", newTemplates);

      this.cards = this.$store.getters.getCardsFromSelectedDeckAndTemplates;
    },
    handleDeckChosen () {
      this.$bvModal.show("player-prompt");
    },
    handleShuffleChange (val) {
      this.shuffled = val;
    },
    isSelected (id) {
      return this.selectedDecks.find((v) => {
        return v.id == id;
      });
    },
    handleSelectDeck (deck, index) {
      this.lastDeckClicked = index;
      const found = this.selectedDecks.find(selectedDeck => selectedDeck.id == deck.id);
      const selectedDecks = found ? this.selectedDecks.filter(selectedDeck => selectedDeck.id != deck.id) : [...this.selectedDecks, deck];

      this.setTemplates(selectedDecks.reduce((decks, deck) => ({ ...decks, [deck.id]: "standard" }), {})) ;
      this.$store.dispatch("setSelectedDecks", selectedDecks);
    },

    preselectCards () {
      if (this.$store.getters.loggedIn) {
        this.$bvModal.show("preselect-cards");
      } else {
        this.$bvModal.show("player-prompt");
      }
    },

    confirm () {
      if (this.$store.getters.loggedIn) {
        this.$store.dispatch("getMyGames").then((resp) => {
          var gamestarted = false;
          resp.forEach((val) => {
            var time = new Date(val.endedAt).getTime();
            var current = new Date().getTime();
            if (current < time) {
              gamestarted = true;
            }
          });

          if (typeof resp == "undefined") {
            this.$bvModal.show("confirm-prompt");
          } else {
            if (resp.length == 0) {
              this.$bvModal.show("confirm-prompt");
            } else {
              if (!gamestarted) {
                this.$bvModal.show("confirm-prompt");
              } else {
                this.playerPrompt();
              }
            }
          }
        });
      } else {
        this.playerPrompt();
      }
    },

    handleConfirmation () {
      this.$bvModal.hide("confirm-prompt");
      this.playerPrompt();
    },

    playerPrompt () {
      this.$bvModal.show("player-prompt");
    },
    async play () {
      if (this.organizations?.[0]?.hats) {
        const hatId = this.organizations[0].hats.find(hat =>  hat.user.id == this.user.id).id;

        const isStillAllowedToPlay = await this.$store.dispatch("isStillAllowedToPlay");

        !isStillAllowedToPlay && await this.$store
          .dispatch("newOrganizationGame", {
            user: this.user.id,
            hat: hatId,
          }).catch((err) => {
            console.log(err);
          });

        var payload = {
          decks: this.selectedDecks,
          shuffled: this.shuffled,
        };

        this.$store.dispatch("getMyGames");

        this.$store.dispatch("setGameDecks", payload).then(() => {
          if (this.selectedDecks.length == 1) {
            this.$router.push({
              name: "PlayByDeck",
              params: {
                deck: this.lastDeckClicked,
              },
            });
          } else {
            this.$router.push({
              name: "PlayStandard",
              props: {
                decks: this.selectedDecks,
              },
            });
          }
        });
      } else {
        this.$router.push({
          name: "PlayByDeck",
          params: {
            deck: this.lastDeckClicked,
          },
        });
      }
    },
    appText (text) {
      return this.$store.getters.getAppTexts[text]?.content ?? "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../node_modules/swiper/swiper-bundle.css";
@import "@/styles/base.scss";
@import "@/styles/variables.scss";
@mixin maintain-ratio($ratio: 1 1) {
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));

  width: $width;
  height: 0;
  padding-bottom: $height;
}

.deck-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
}

.deck-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-link {
  @include maintain-ratio(2 3);
  border-radius: 15px;

  h3 {
    position: absolute;
    text-align: center;
    top: 45%;
    width: 100%;
    font-size: 1.7vw;
    font-weight: bold;
  }
}

.black {
  background-color: $black !important;
  color: $white;
}

.green {
  background-color: $green !important;
  color: $white;
}

.blue {
  background-color: $blue !important;
  color: $pink;
}

.yellow {
  background-color: $orange !important;
  color: $red;
}

.red {
  background-color: $red !important;
  color: $orange;
}

.hubu-btn {
  background-color: $white;
  color: $red;
  border-color: $red;
}

.hubu-btn:disabled {
  background-color: $black !important;
  color: $white;
  opacity: 1;
  border-color: $black;
}

.selected {
  -moz-box-shadow: inset 0 0 45px #000;
  -webkit-box-shadow: inset 0 0 45px #000;
  box-shadow: inset 0 0 45px #000;
}

.cursor-pointer {
  cursor: pointer;
}

.isMobile {
  @include maintain-ratio(2 3);
  border-radius: 15px;

  h3 {
    position: absolute;
    text-align: center;
    top: 45%;
    width: 100%;
    font-size: 6.2vw;
    font-weight: bold;
  }

  .lowertext {
    font-size: 111px !important;
  }

  .uppertext {
    font-size: 111px !important;
  }
}

.info-arrow {
  position: relative;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: 15vw;
  height: 25vw;
  z-index: 99999;
  color: $red;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.mobileMaxWidth {
  max-width: 70% !important;
}

.iPadMaxWidth {
  max-width: 45% !important;
}

.iPad {
  h3 {
    top: 40% !important;
  }
}
</style>
