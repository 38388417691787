<template>
  <div
    class="card"
    :style="'background-color: ' + link.bg_color + '; color: ' + link.text_color + ';'"
    @click="selectDeck"
  >
    <h6 class="uppertext">
      {{ link.description }}
    </h6>
    <h3
      class="middletext"
      v-html="link.title"
    ></h3>
    <div
      class="select-mark"
      :class="{
        'mobile-mark': isMobile  
      }"
    >
      <font-awesome-icon
        :icon="{ prefix: 'fas', iconName: 'check' }"
      ></font-awesome-icon>
    </div>
    <h6 class="lowertext">
      {{ link.subtitle }}
    </h6>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "SelectableDeck",
  props: {
    link: {
      type: Object,
      required: true,
    },
    selectDeck: Function,
  },

  computed: {
    iPad () {
      return (window.screen.width/window.screen.height > 0.65 && window.screen.width/window.screen.height < 0.9 ) ? true : false;
    },
    isMobile () {
      return Cookies.get("mobile") === "false" ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../node_modules/swiper/swiper-bundle.css";
@import "@/styles/base.scss";
@import "@/styles/variables.scss";

.middletext {
  font-size: 1.6vw;
  font-weight: 700;
}

.card {
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border-radius: 15px;
  padding-top: 1.6rem;
  padding-bottom: 1rem;
  aspect-ratio: 2.35/3.5;
  width: 20%;
  max-width: 220px;
}

.card-link {
  border-radius: 15px;
  height: 440px;

  h3 {
    position: absolute;
    text-align: center;
    top: 42%;
    width: 100%;
    font-size: 1.7vw;
    font-weight: bold;
  }

  .uppertext {
    position: absolute;
    top: 10px;
    text-align: center;
    width: 100%;
    letter-spacing: 0.15vw;
    font-size: 1.3vw;
  }

  .lowertext {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    font-size: 1vw;
    letter-spacing: 0.15vw;
  }
}

.black {
  background-color: $black !important;
  color: $white;
}

.green {
  background-color: $green !important;
  color: $white;

  .uppertext,
  .lowertext {
    color: $card-green;
  }
}

.blue {
  background-color: $blue !important;
  color: $pink;

  .uppertext,
  .lowertext {
    color: $card-blue;
  }
}

.yellow {
  background-color: $orange !important;
  color: $red;

  .uppertext,
  .lowertext {
    color: $card-orange;
  }
}

.red {
  background-color: $red !important;
  color: $orange;

  .uppertext,
  .lowertext {
    color: $card-red;
  }
}

.select-mark {
  display: none;
}

.selected {
  .select-mark {
    display: inline;
    position: absolute;
    top: 15%;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: 2vw;
  }
}

.uppertext, .lowertext {
  font-size: 1.3vw;
}

.isMobile {
  h3 {
    position: absolute;
    text-align: center;
    top: 45%;
    width: 100%;
    font-size: 6.2vw;
    font-weight: bold;
  }

  .uppertext {
    position: absolute;
    top: 15px;
    text-align: center;
    width: 100%;
    font-size: 1.3vw;
    letter-spacing: 0.15vw;
  }

  .lowertext {
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: 100%;
    font-size: 3.6vw;
    letter-spacing: 0.15vw;
  }
}

.mobile-mark {
  font-size: 8vw !important;
}

.iPad{
  h3 {
    position: absolute;
    text-align: center;
    top: 40%;
    width: 100%;
    font-size: 5.2vw;
    font-weight: bold;
  }

  .uppertext {
    position: absolute;
    top: 15px;
    text-align: center;
    width: 100%;
    font-size: 1.3vw;
    letter-spacing: 0.15vw;
  }

  .lowertext {
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: 100%;
    font-size: 2.6vw;
    letter-spacing: 0.15vw;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
   .uppertext{ font-size: 1vw; }
  .middletext{ font-size: 1.8vw; }
   .uppertext{ font-size: 1vw; }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
   .uppertext{ font-size: 1vw; }
  .middletext{ font-size: 1.8vw; }
   .uppertext{ font-size: 1vw; }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .uppertext{ font-size: 1vw; }
  .middletext{ font-size: 1.8vw; }
  .lowertext{ font-size: 1vw; }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .uppertext{ font-size: 1vw; }
  .middletext{ font-size: 1.8vw; }
  .lowertext{ font-size: 1vw; }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .uppertext{ font-size: 1vw; }
  .middletext{ font-size: 1.4em; }
  .lowertext{ font-size: 1vw; }
}
</style>