<template>
  <div>
    <b-modal
      v-if="show"
      id="preselect-cards"
      :title="$t('custom_deck')"
      header-bg-variant="red"
      header-text-variant="white"
      body-bg-variant="white"
      body-text-variant="dark"
      footer-bg-variant="white"
      footer-text-variant="red"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            {{ $t("custom_deck_description") }}
          </b-col>
        </b-row>

        <b-row>
          <b-col
            lg="12"
            sm="12"
          >
            <template v-for="(deck, index) in selectedDecks">
              <PreselectCardsDropdown
                :key="'dd-'+index"
                :templates="deck.templates"
                :deck-title="deck.title"
                :master-deck-id="deck.id"
                @value-changed="(template) => handleValueChanged(deck.id, template)"
              ></PreselectCardsDropdown>
            </template>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="outline-red"
            size="lg"
            class="float-right"
            @click="select"
          >
            {{ $t("yes") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PreselectCardsDropdown from "./PreselectCardsDropdown.vue";

export default {
  name: "PreselectCards",

  components: {
    PreselectCardsDropdown,
  },

  props: {
    selectedDecks: {
      required: true,
    },
    onTemplateChange: Function,
  },

  data () {
    return {
      show: true,
      currentDecks: null,
      selected: Object.values(this.selectedDecks).reduce((selected, deck) => ({ ...selected, [deck.id]: "standard" }), {}),
      options: {},
      templates: {},
    };
  },
  computed: {
    decks () {
      this.$store.getters.getDecks[this.$i18n.locale].forEach((val) => {
        this.options.push({ value: val.id, text: val.title });
      });
      return this.$store.getters.getDecks[this.$i18n.locale];
    },
  },
  watch: {
    selectedDecks () {
      this.selected = Object.values(this.selectedDecks).reduce((selected, deck) => ({ ...selected, [deck.id]: "standard" }), {});
    },
  },
  async mounted () {
    await this.$store.dispatch("isStillAllowedToPlay");
  },
  methods: {
    handleValueChanged (deckId, template) {
      this.selected[deckId] = template;
      this.onTemplateChange(this.selected);
    },
    
    select () {
      this.$bvModal.hide("preselect-cards");
      this.$emit("deckChosen");
    },

    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object"
        ? this.$store.getters.getAppTexts[text].content
        : text;
    },
  },
};
</script>
